<template>
  <el-dialog
    v-model="dialogVisible"
    :title="dialogTitle"
    :width="dialogWith + '%'"
  >
    <div class="gps-form">
      <el-form
        ref="ruleFormRef"
        :show-message="false"
        :model="ruleForm"
        :rules="rules"
        status-icon
        label-width="110px"
      >
        <el-row :gutter="10" v-for="(rr, ir) in ruleForm_temp" :key="'r' + ir">
          <el-col
            :span="24 / rr.length"
            :offset="0"
            v-for="it in rr"
            :key="'i' + it.field"
            ><el-form-item
              :label="it.label"
              :prop="it.field"
              v-if="it.type != 'empty'"
            >
              <div class="flex-row" v-if="it.type == 'ipt_ipts'">
                <el-input
                  v-for="(ipit, ip_i) in ruleForm[it.field]"
                  :key="ip_i"
                  clearable
                  :placeholder="ruleForm[it.field][ip_i]"
                  v-model="ruleForm[it.field][ip_i]"
                />
              </div>
              <el-tree-select
                v-if="it.type == 'tsel'"
                v-model="ruleForm[it.field]"
                :data="it.data"
                filterable
              />
              <el-input
                v-if="it.type == 'ipt'"
                clearable
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
              />
              <el-input
                v-if="it.type == 'pwd'"
                clearable
                type="password"
                show-password
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'dtr'"
                v-model="ruleForm[it.field]"
                type="datetimerange"
                range-separator="To"
                :start-placeholder="it.title_s"
                :end-placeholder="it.title_e"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'date'"
                v-model="ruleForm[it.field]"
                type="date"
                :placeholder="it.label"
                value-format="YYYY-MM-DD"
              />
              <el-date-picker
                clearable
                v-if="it.type == 'datetime'"
                v-model="ruleForm[it.field]"
                type="datetime"
                :placeholder="it.label"
                value-format="YYYY-MM-DD hh:mm:ss"
              />
              <el-radio-group
                v-model="ruleForm[it.field]"
                v-if="it.type == 'radio'"
              >
                <el-radio
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.value"
                  >{{ item.name }}</el-radio
                >
              </el-radio-group>
              <el-radio-group
                v-model="ruleForm[it.field]"
                v-if="it.type == 'radio_img'"
              >
                <el-radio
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.value"
                  ><el-image :src="item.path" fit="fill"></el-image>
                </el-radio>
              </el-radio-group>
              <el-select
                clearable
                v-if="it.type == 'sel'"
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
                filterable
                @change="it.change && it.change($event, it, callbackForm)"
              >
                <el-option
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value.toString()"
                />
              </el-select>
              <div class="flex-row" v-if="it.type == 'sel_btn'">
                <el-select
                  clearable
                  v-model="ruleForm[it.field]"
                  :placeholder="it.label"
                  filterable
                  @change="it.change && it.change($event, it, callbackForm)"
                >
                  <el-option
                    v-for="item in it.data"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value.toString()"
                  />
                </el-select>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="small"
                  @click="it.click && it.click($event, it, callbackFormOther)"
                  >Add</el-button
                >
              </div>
              <el-select
                clearable
                v-if="it.type == 'msel'"
                multiple
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
                filterable
              >
                <el-option
                  v-for="item in it.data"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
              <el-switch
                v-if="it.type == 'switch'"
                v-model="ruleForm[it.field]"
                active-value="1"
                inactive-value="0"
                active-color="#13ce66"
                inactive-color="#ff4949"
              />
              <gps-upload
                v-if="it.type == 'upload'"
                :field="it.field"
                :imgPath="ruleForm[it.field]"
                @UploadEvent="UploadEvent"
              ></gps-upload>
              <el-tree-select
                clearable
                filterable
                v-if="it.type == 'mtree'"
                v-model="ruleForm[it.field]"
                :data="it.data"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
              />
              <div class="flex-row" v-if="it.type == 'veh_ipts'">
                <span
                  @click="
                    click_veh({
                      form: ruleForm,
                      it: it,
                      value: ruleForm[it.field],
                      fd: it.field,
                    })
                  "
                  >{{
                    ruleForm[it.field] == null ? 0 : ruleForm[it.field].length
                  }}</span
                >
              </div>
              <!-- <el-input
                v-if="it.type == 'veh_ipts'"
                clearable
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
                @click="
                  click_veh({
                    form: ruleForm,
                    it: it,
                    value: ruleForm[it.field],
                    fd: it.field,
                  })
                "
              /> -->
              <div class="flex-row" v-if="it.type == 'group_ipts'">
                <span
                  @click="
                    click_group({
                      form: ruleForm,
                      it: it,
                      value: ruleForm[it.field],
                      fd: it.field,
                    })
                  "
                  >{{
                    ruleForm[it.field] == null ? 0 : ruleForm[it.field].length
                  }}</span
                >
              </div>
              <!-- <el-input
                v-if="it.type == 'group_ipts'"
                clearable
                v-model="ruleForm[it.field]"
                :placeholder="it.label"
                @click="
                  click_group({
                    form: ruleForm,
                    it: it,
                    value: ruleForm[it.field],
                    fd: it.field,
                  })
                "
              /> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">{{
          $t("commKey.Cancel")
        }}</el-button>
        <el-button type="primary" @click="submitForm()">
          {{ $t("commKey.Confirm") }}
        </el-button>
      </span>
    </template>
    <el-dialog
      v-model="dialogVisible_veh"
      :title="dialog_title"
      :width="dialogW"
    >
      <div v-show="is_veh">
        <div class="search">
          <el-select v-model="veh_zt" placeholder="" clearable filterable>
            <el-option
              v-for="item in options_zt"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-tree-select
            v-model="group_val"
            :data="groupTree"
            :props="props_group"
            check-strictly
            :render-after-expand="false"
            style="width: 240px"
          />

          <el-input
            v-model="veh_cph"
            :placeholder="$t('map.cph_name')"
            clearable
          ></el-input>
          <el-button
            type="primary"
            size="default"
            @click="btnSearchVeh('search')"
            >{{ $t("map.track_cx") }}</el-button
          >
          <el-button
            v-if="veh_zt == 1"
            type="success"
            size="default"
            @click="btnSearchVeh('bind')"
            >{{ $t("map.bangding") }}</el-button
          >
          <el-button
            v-if="veh_zt == 2"
            type="primary"
            size="default"
            class="is-round"
            @click="btnSearchVeh('unbind')"
            >{{ $t("map.jiebang") }}</el-button
          >
        </div>
        <el-table
          :data="tableData"
          style="width: 100%"
          height="500"
          row-key="id"
          ref="vehData"
          :default-checked-keys="defaultCheckedKeys"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="30" />
          <el-table-column
            :label="cls.name"
            :width="cls.width"
            v-for="cls in tableCols"
            :key="cls.field"
          >
            <template #default="{ row }">
              <span v-if="cls.render" v-html="cls.render(row)"></span>
              <span v-else>{{ row[cls.field] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-show="!is_veh">
        <el-scrollbar>
          <el-tree
            :data="tableData"
            :props="props_group"
            @check-change="checkChange"
            ref="treeGroup"
            show-checkbox
            node-key="node_id"
            :default-checked-keys="chk_keys"
            default-expand-all
            :default-expanded-keys="def_keys"
          >
            <template #default="{ node, data }">
              <label class="prefix" v-if="data.nodetype != '3'"
                ><img
                  style="width: 18px; height: 18px; margin-top: 3px"
                  src="../../../assets/meun/jk.png"
                  alt=""
              /></label>
              <label
                style="
                  padding-left: 5px;
                  font-size: 13px;
                  color: #000;
                  margin-top: 3px;
                "
                >{{ node.label }}</label
              >
            </template>
          </el-tree>
        </el-scrollbar>
      </div>
    </el-dialog>
  </el-dialog>
</template>

<script setup>
import { Watch } from "@element-plus/icons-vue";
import { isNumber } from "element-plus/es/utils";
import {
  ref,
  toRefs,
  defineExpose,
  reactive,
  getCurrentInstance,
  watch,
  defineEmits,
  toRaw,
} from "vue";
import { useI18n } from "vue-i18n";

import GpsUpload from "../../../components/GpsUpload.vue";
import { ElMessage } from "element-plus";
const { proxy } = getCurrentInstance();
// const { $api } = proxy.appContext.config.globalProperties;
const { t } = useI18n();
const props = defineProps({
  dialogWith: {
    type: Number,
    default: 48,
  },
  dialogTitle: {
    type: String,
    default: "",
  },
  formArray: {
    type: Array,
    default: () => {},
  },
  rowCount: {
    type: Number,
    default: 2,
  },
  row: {
    type: Object,
    default: () => {},
  },
  groupArray: {
    type: Array,
    default: () => {},
  },
});

const emit = defineEmits(["saveEvent", "formEvent", "bindEvent", "BtnEvent"]);

const dialogVisible = ref(false);

const dialogVisible_veh = ref(false);
const is_veh = ref(true);
const tableData = ref([]);
const tableCols = ref([]);
const dialogW = ref("60%");

const def_keys = ref([]);

const options_zt = [
  {
    label: t("map.weifenpei"),
    value: 1,
  },
  {
    label: t("map.yifenpei"),
    value: 2,
  },
];
const veh_zt = ref(1);
const group_val = ref("");
const veh_cph = ref("");
const props_group = {
  value: "node_id",
  label: "node_name",
  children: "children",
};
//请求数据

let ay_table = [
  {
    name: t("map.cph_name"),
    field: "vPlate",
  },
  {
    name: t("map.ssgs_name"),
    field: "cName",
  },
  {
    name: t("map.table_zd") + "ID",
    field: "vDeviceName",
  },
  {
    name: t("map.simka"),
    field: "vSimNo",
  },
  {
    name: t("map.shiyongzhuangtai"),
    field: "vState",
    render: (row) => {
      return row.vState == 1 ? t("map.zhengchang") : t("map.guanbi");
    },
  },
  {
    name: t("map.shijian"),
    field: "vLastUpdated",
  },
];

tableCols.value = ay_table;
const groupData = ref([]);
const chk_keys = ref([]);
const dialog_title = ref(t("map.cheliangfenpei"));
const treeGroup = ref();
let sel_fd = "";
const defaultCheckedKeys = ref([]);
const vehData = ref();
const groupTree = ref([]);

//表单控制
const ruleFormRef = ref();

let ruleFormObj = {};
let rulesObj = {};

const arr = [];
props.formArray.map((item, index) => {
  //赋值操作
  switch (item.type) {
    case "ipt_ipts":
      if (item.field) ruleFormObj[item.field] = item.val.split(",");
      break;
    default:
      if (item.field) ruleFormObj[item.field] = item.val;
      break;
  }
  //验证数据规则
  if (item.required) {
    rulesObj[item.field] = [
      {
        required: true,
        message: item.label + t("commKey.BITIAN"),
        trigger: "blur",
      },
    ];
  }

  // 计算该元素为第几个素组内;
  const itemIndex = Math.floor(index / props.rowCount);
  // 判断对应的索引是否存在
  if (!arr[itemIndex]) {
    arr[itemIndex] = [];
  }
  if (item.type != "") arr[itemIndex].push(item);
});

const ruleForm_temp = reactive(arr);

let ruleForm = reactive(ruleFormObj);
const rules = reactive(rulesObj);
let formParaArrayData = {};
const formParaArray = reactive(formParaArrayData);

const submitForm = async () => {
  // if (!formEl) return;
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      // console.log(ruleFormObj);toRaw
      // emit("saveEvent", ruleFormObj);
      // console.log(toRaw(ruleForm));
      emit("saveEvent", ruleForm);
    } else {
      console.log("error submit!", fields);
    }
  });
};

const callbackForm = (obj) => {
  if (obj.toType) {
    let val = null;
    switch (obj.toType) {
      case "ipt_ipts":
        val = [];
        for (let i = 0; i < parseInt(obj.val); i++) {
          val.push("CH" + (i + 1));
        }
        break;
    }
    ruleForm[obj.toField] = val;
  } else {
    emit("formEvent", obj);
  }
};

const callbackFormOther = (obj) => {
  emit("BtnEvent", obj);
};

watch(dialogVisible, (val) => {
  if (!val) {
    // ruleFormRef.resetFields();
  }
});
watch(props.row, (val) => {
  // console.log("car gps", val);
  //手动清空数据
  for (let p in ruleForm) {
    delete ruleForm[p];
    // Vue.delete(a,name)
  }
  if (val.id == 0) {
    let temp_row = {};
    props.formArray.map((item, index) => {
      //赋值操作
      switch (item.type) {
        case "ipt_ipts":
          if (item.field) temp_row[item.field] = item.val.split(",");
          break;
        default:
          if (item.field) temp_row[item.field] = item.val;
          break;
      }
    });

    // console.log(temp_row);

    Object.assign(ruleForm, temp_row);
  } else {
    Object.assign(ruleForm, val);
  }
  // console.log("row", ruleForm);
});

// const { text } = toRefs(props);
// const formatText = `Hi,${text && text.value}`;

///////////////////
const propoption = {
  icon: "icon",
  value: "gps_id",
  label: "nodeName",
  children: "children",
};

////////////////////////////////////////////
const UploadEvent = (obj) => {
  ruleForm[obj.field] = obj.img;
};

const search_group = (ayItems) => {
  ayItems.forEach((it) => {
    if (it.id == props.row.uCompanyID) {
      groupTree.value = [it];
    } else {
      if (it.children) search_group(it.children);
    }
  });
};

////////////////////////
const click_veh = (obj) => {
  console.log("groupArray", props.row, props.groupArray);
  if (props.groupArray[0].id == props.row.uCompanyID) {
    groupTree.value = [props.groupArray[0]];
    // def_keys.value = [props.groupArray[0].node_id];
  } else {
    search_group(props.groupArray[0].children);
  }
  // props.groupArray.forEach((it) => {
  //   if (it.id == props.row.uCompanyID) {
  //     groupTree.value = [it];
  //   } else {
  //     search_group(it.children);
  //   }
  // });

  dialogW.value = "60%";
  sel_fd = obj.fd;
  veh_zt.value = 1;
  dialog_title.value = t("map.cheliangfenpei");
  let chk = [];
  for (let p in obj.value) {
    chk.push(obj.value[p]);
  }
  console.log(chk);
  defaultCheckedKeys.value = chk;
  // console.log(defaultCheckedKeys.value);
  // console.log(obj.it.data);
  is_veh.value = true;
  tableData.value = obj.it.data;
  dialogVisible_veh.value = true;
  if (vehData.value) {
    vehData.value.clearSelection();
  }

  // multipleSelection.value = [];
};
const click_group = (obj) => {
  dialogW.value = "30%";
  sel_fd = obj.fd;
  dialog_title.value = t("map.chezufenpei");
  // console.log(obj.it.data);
  if (obj.value != null && typeof obj.value === "string") {
    let val = obj.value.replace(/\b,\b/g, "");
    chk_keys.value = val.split(",");
  } else {
    chk_keys.value = obj.value;
  }

  is_veh.value = false;
  tableData.value = obj.it.data;
  def_keys.value = [obj.it.data[0].node_id];
  dialogVisible_veh.value = true;
};
//多选处理
const multipleSelection = ref([]);
const handleSelectionChange = (val) => {
  multipleSelection.value = val;
  // let chk = multipleSelection.value.map((it) => it.id);
  // ruleForm[sel_fd] = chk;
  emit("bindEvent", { type: "multipleSelection", rows: val });
};

const btnSearchVeh = (flag) => {
  if (flag == "search") {
    // console.log("group_val", group_val);
    // if (group_val.value != "" && !/^\d+$/.test(group_val.value)) {
    //   ElMessage.error(t("map.qingxuanzechezu"));
    //   return;
    // }
    let ids = "";
    if (ruleForm["uVelIDS"] && ruleForm["uVelIDS"] instanceof Array) {
      ids = ruleForm["uVelIDS"].join(",");
    }
    let group_id = "";
    if (group_val.value != "" && /^\d+$/.test(group_val.value)) {
      group_id = group_val.value;
      // ElMessage.error(t("map.qingxuanzechezu"));
      // return;
    }
    // console.log(props.row, ruleForm);
    emit("bindEvent", {
      type: flag,
      veh_zt: veh_zt.value,
      group_val: group_id, //group_val.value,
      veh_cph: veh_cph.value,
      is_bind: 1,
      cid: props.row.uCompanyID,
      ids: ids,
    });
  } else if (flag == "bind") {
    // console.log("ay_bind0", ruleForm, sel_fd, typeof ruleForm[sel_fd]);
    let ay_bind = [];
    if (typeof ruleForm[sel_fd] == "array") {
      ay_bind = ruleForm[sel_fd];
    } else if (typeof ruleForm[sel_fd] == "object") {
      for (let key in ruleForm[sel_fd]) {
        if (ruleForm[sel_fd].hasOwnProperty(key)) {
          ay_bind.push(ruleForm[sel_fd][key]);
        }
      }
    }

    // console.log("ay_bind1", ay_bind);

    multipleSelection.value.forEach((it) => {
      if (!ay_bind.includes(it.id)) {
        ay_bind.push(it.id);
      }
    });
    // console.log("ay_bind2", ay_bind, multipleSelection.value);
    ruleForm[sel_fd] = ay_bind;
    // console.log(sel_fd, ruleForm[sel_fd]);
    // emit("bindEvent", { type: flag, rows: multipleSelection.value });
    emit("bindEvent", {
      type: "search",
      veh_zt: veh_zt.value,
      group_val: group_val.value,
      veh_cph: veh_cph.value,
      is_bind: 1,
      cid: props.row.uCompanyID,
      ids: ruleForm["uVelIDS"].join(","),
    });
  } else if (flag == "unbind") {
    //
    let ay_bind = [];
    if (typeof ruleForm[sel_fd] == "array") {
      ay_bind = ruleForm[sel_fd];
    } else if (typeof ruleForm[sel_fd] == "object") {
      for (let key in ruleForm[sel_fd]) {
        if (ruleForm[sel_fd].hasOwnProperty(key)) {
          ay_bind.push(ruleForm[sel_fd][key]);
        }
      }
    }
    let index;
    multipleSelection.value.forEach((it) => {
      index = ay_bind.indexOf(it.id); // 查找元素的索引位置

      if (index !== -1) {
        ay_bind.splice(index, 1); // 如果找到元素，就删除它
        // console.log("元素已删除:", array);
      }
    });
    ruleForm[sel_fd] = ay_bind;
    emit("bindEvent", {
      type: "search",
      veh_zt: veh_zt.value,
      group_val: group_val.value,
      veh_cph: veh_cph.value,
      is_bind: 1,
      cid: props.row.uCompanyID,
      ids: ruleForm["uVelIDS"].join(","),
    });
    ////////////////////////
  }

  //bindEvent
};

const setSearch = (ay) => {
  console.log("setSearch", ay);
  tableData.value = ay;

  // let chk_chk = [];
  // ruleForm[sel_fd].forEach((it) => {
  //   tableData.value.forEach((ik) => {
  //     if (parseInt(ik.id) == parseInt(it)) {
  //       chk_chk.push(ik);
  //     }
  //   });
  // });
  // console.log("chk_chk", chk_chk);
  // chk_chk.forEach((im) => {
  //   vehData.value.toggleRowSelection(im, true);
  // });
};

const checkChange = () => {
  let ay = treeGroup.value.getCheckedNodes();
  let chk = [];
  ay.forEach((it) => {
    if (/^\d+$/.test(it.node_id)) {
      chk.push(it.node_id);
    }
  });
  ruleForm[sel_fd] = chk;
  // console.log(chk);
};

const clearVal = (val) => {
  for (let p in val) {
    ruleForm[p] = val[p];
  }
  // for (let p in ruleForm) {
  //   delete ruleForm[p];
  //   // Vue.delete(a,name)
  // }
};

defineExpose({ dialogVisible, setSearch, clearVal });
</script>

<style lang="scss" scoped>
::v-deep(.el-select) {
  width: 100%;
}
::v-deep(label) {
  margin-bottom: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
::v-deep .el-date-editor.el-input {
  width: 100% !important;
  height: 30px !important;
}
.search {
  display: flex;
  margin-bottom: 10px;
  margin-top: -10px;
}
.search ::v-deep .el-select:nth-child(1) {
  width: 150px;
}
.search ::v-deep .el-input--small:nth-child(3) {
  width: 240px;
}
.search ::v-deep .el-button--success {
  background: #03b356 !important;
}
::v-deep .el-button {
  border-radius: 20px;
  padding: 0px 20px;
  font-size: 12px;
}
.search ::v-deep .is-round {
  background: #f9543e !important;
}
</style>
